.logo {
  margin-top: 10px;
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .logo {
    animation: logo-spin infinite 10s linear;
  } */
}

.footer {
  margin-top: 10px;
  color: grey;
  font-size: 9px;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
